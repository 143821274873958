<template>
  <div :class="$style.categories">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="left"
      label-width="100px"
    >
      <el-form-item label="Название" prop="name">
        <el-input @input="handleNameInput" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="В фильтрах" prop="isDisplayed">
        <el-checkbox v-model="form.isDisplayed"></el-checkbox>
      </el-form-item>
    </el-form>

    <div :class="$style.tags">
      <span :class="$style.label">Варианты</span>
      <EditableTag
        :options="this.form.attributeOptions"
        :placeholder="'атрибут'"
        :class="$style.block"
        @delete="handleClose($event)"
      />
    </div>

    <el-button type="primary" @click="submitForm('form')">Создать</el-button>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import { transliterate } from '@/helpers/slug'
import EditableTag from '@/components/moleculs/EditableTag.vue'

export default {
  components: { EditableTag },
  data() {
    return {
      form: {
        name: '',
        slug: '',
        isDisplayed: false,
        attributeOptions: []
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change'
          }
        ]
      },
      options: [],
      inputTagVisible: false,
      inputTagValue: ''
    }
  },

  methods: {
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
    handleClose(value) {
      const index = this.form.attributeOptions.findIndex(
        (item) => item.value === value
      )
      this.form.attributeOptions.splice(index, 1)
    },

    resetForm() {
      this.form = {
        name: '',
        slug: '',
        attributeOptions: []
      }
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          const result = await delivery.ProductsCore.AttributesActions.create(
            this.form
          )
          if (result.error) {
            alert('Ошибка создания атрибута: ' + result.error)
          } else {
            alert('Атрибут успешно создан')
            this.resetForm()
          }
          loading.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.categories {
  padding: 1rem;
  label {
    margin: 0;
  }
  .tags {
    margin-bottom: 1rem;
    .label {
      font-weight: bold;
      color: #606266;
      font-size: 0.875rem;
      margin-right: 2rem;
    }
    .block {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2rem;
      & > div input {
        width: 20rem;
      }
    }
  }
}
</style>
